import React, { useEffect } from "react";
import Header from './Header'
import Contact from '../Components/Contact';
import MainImg from "../Images/NewHomeBanner.png";
import homebanner2 from "../Images/homebanner2.png";
import mainReviewImage from '../Images/ReviewMainImage.png'
// import ProjectSummary from "../Components/ProjectSummary";
// import carasoualim1 from "../Images/IMG-20230703-WA0066 1.png";
// import carasoualim2 from "../Images/IMG-20230703-WA0015 2.png";
// import carasoualim3 from "../Images/Aboutbanner.png";
// import lastbanner from "../Images/PHOT.png";
// import LeavesImage from '../Images/GrassImage.png'
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { IoChevronForwardCircleOutline } from "react-icons/io5";
import OurWorkImg from '../Images/Our Work Image.png'
import "../styles/home.css";
import Counter from "./Counter";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import HomeSummary from "./HomeSummary";
import { toast } from "react-toastify";

export default function Home() {

  const navigate = useNavigate()

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [mobile_no, setMobileNo] = useState("")
  const [message, setMessage] = useState("")
  const [aboutUs, setAboutUs] = useState([])
  const [reviews, setReviews] = useState([])

  function scrollToFounder() {
    window.scrollTo(0, 1500)
  }

  useEffect(() => {
    axios.get("/get_aboutus")
      .then(res => {
        console.log("res.data in homes's About Us", res.data.data);
        setAboutUs(res.data.data)
      }).catch(err => console.log(err))

    axios.get("/get_reviews")
      .then(res => {
        console.log("res.data in homes's review", res.data.data)
        setReviews(res.data.data)
      }).catch(err => console.log(err))
  }, [])

  // Perform email validation using a regular expression
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  // const mobileRegex = /^\d{10}$/;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!emailRegex.test(e.target.value)) {
      e.target.setCustomValidity('Email is incorrect.');
    } else {
      e.target.setCustomValidity('');
    }
  };


  const handleMobileChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    const truncatedValue = numericValue.slice(0, 10); // Limit to 10 digits
    setMobileNo(truncatedValue);

    if (numericValue.length > 11) {
      e.target.setCustomValidity('Mobile no should be a maximum of 10 digits');
    } else {
      e.target.setCustomValidity('');
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault()

    // Perform email validation using a regular expression
    // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    // const mobileRegex = /^\d{10}$/;

    // if (!emailRegex.test(email) && !mobileRegex.test(mobile_no)) {
    //     toast.success('Please enter a valid email address or a 10-digit mobile number.');
    //     return;
    // } else if (!emailRegex.test(email)) {
    //   toast.success('Please enter a valid email address');
    //   return;
    // } else if (!mobileRegex.test(mobile_no)) {
    //   toast.success('Please enter a 10-digit mobile number');
    //   return;
    // } 

    let newData = { name, email, message, mobile_no }
    axios.post('/add_contact_us', newData)
      .then(res => {
        console.log(res.data)
        toast.success("We will connect you soon. Thank You!")
      }).catch(err => {
        console.log(err)
        toast.error("Something went wrong")
      })

    setName("")
    setEmail("")
    setMessage("")
    setMobileNo("")
  }

  const HomeSummaryTitle = "SERVICES";

  const HomeSummaryDetails = [
    {
      redirectTo: "/completed",
      summaryFeature: "COMPLETED PROJECTS",
      id: 1
    },
    {
      redirectTo: "/property",
      summaryFeature: "PROPERTIES",
      id: 2
    },
    {
      redirectTo: "/ongoing",
      summaryFeature: "ONGOING PROJECTS",
      id: 3
    },
  ];

  // const [scrollPosition, setScrollPosition] = useState(0);

  // const handleScroll = () => {
  //   const currentPosition = window.scrollY;
  //   setScrollPosition(currentPosition);
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // console.log(scrollPosition);

  // const caroDetails = [
  //   {
  //     image: carasoualim1,
  //     review: "Beautiful place",
  //     name: "Sakshi patel"
  //   },
  //   {
  //     image: carasoualim2,
  //     review: "Very Affordable Plots",
  //     name: "Nishant Sharma"
  //   },
  //   {
  //     image: carasoualim3,
  //     review: "Good environment",
  //     name: "Arnav Mehra"
  //   },
  // ]

  return (
    <div className="home-main">
      <Header />
      {/* banner section */}

      <div className='ongoing-git-form'>
        <img src={MainImg} className="image-form-main img-fluid w-100" alt="" />
        <div>
          <div className='form-class'>
            <form className='form-main' onSubmit={handleFormSubmit}>
              <div>
                <h1 className="git-heading">Get In Touch</h1>
              </div>
              <div>
                <input type="text" placeholder='Name'
                  required
                  onChange={(e) => setName(e.target.value)} value={name} />
              </div>
              <div>
                <input type="text" placeholder='Email id'
                  required
                  onChange={handleEmailChange} value={email} />
              </div>
              <div>
                <input type="text" placeholder='Contact No.'
                  required
                  onChange={handleMobileChange} value={mobile_no} />
              </div>
              <div>
                <textarea id="" cols="23" rows="2" placeholder='Message'
                  required
                  onChange={(e) => setMessage(e.target.value)} value={message} />
              </div>
              <button type="submit" className='btn'>Send</button>
            </form>
          </div>
        </div>
      </div>

      {/* second banner */}
      <div className="row secondbanner ">
        <div className=" col-lg-3">
          <div className="col-lg-10 pe-4 col-sm-12 ms-lg-5 ms-2">
            <p className="hometitlebanner2">About Us</p>

            {aboutUs.length > 0 ?
              < div >
                <p className="hometext mt-3">
                  {aboutUs[0].short_description}
                </p>
                <span className="hometext-end">......</span>
              </div> : <p className="hometext mt-3"> No Data Available </p>
            }


            <div className="d-flex justify-content-end">
              <button className="btnhome p-3 mt-5 rounded-2 mb-5 ms-0"
                onClick={() => {
                  navigate('/about')
                  scrollToFounder()
                }}>Know more</button>
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          {/* <div className=" col-lg-12 secondbannerhomeblank mx-4 d-none d-lg-block"> </div> */}
          <div className="second-banner-image">
            <img src={homebanner2} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
      {/* <div>
        <img src={LeavesImage} className="img-fluid setimgleaves" alt="" />
      </div> */}
      {/* propertystaussection */}
      <div className="home-summary">
        <HomeSummary HomeSummaryTitle={HomeSummaryTitle}
          HomeSummaryDetails={HomeSummaryDetails} />
      </div>

      {/* carasoul of home */}
      {
        reviews.length > 0 &&
        <div className="main-carousel">
          <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">

            {/* <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="0"
              className="active  visually-hidden"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              className=" visually-hidden"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              className="  visually-hidden"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div> */}

            <div className="carousel-inner">
              {reviews.map((review, index) => {
                return (
                  <div
                    className={`carousel-item carousel-image 
                  ${(index === 0) ? 'active' : ''}`}
                    data-bs-interval="3000"
                  >
                    {/* <img src={review.image} className="d-block w-100 h-100" alt="..." /> */}
                    <img src={mainReviewImage} className="d-block w-100 h-100" alt={review.title} />
                    <div className="carousel-caption d-sm-block texthome">
                      <h5 className="carasoualtitle">{review.title}</h5>
                      <p className="carasoualtext">
                        {review.description}
                      </p>
                      <p className="carasoualtext-name">
                        {review.reviewer_name}
                      </p>
                    </div>
                  </div>
                )
              })}
              {/* <div className="carousel-item carousel-image" data-bs-interval="2000">
              <img src={carasoualim2} className="d-block w-100 h-100" alt="..." />
              <div className="carousel-caption d-sm-block texthome">
                <h5 className="carasoualtitle">Review</h5>
                <p className="carasoualtext">
                  Beautiful place and very Affordable Plots Sakshi patel
                </p>
              </div>
            </div> */}
              {/* <div className="carousel-item carousel-image" data-bs-interval="2000">
              <img src={carasoualim3} className="d-block w-100 h-100" alt="..." />
              <div className="carousel-caption d-sm-block texthome">
                <h5 className="carasoualtitle">Review</h5>
                <p className="carasoualtext">
                  Beautiful place and very Affordable Plots Sakshi patel
                </p>
              </div>
            </div> */}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="prev"
            >
              <span className=" visually-hidden" aria-hidden="true"></span>
              <span className="carasoualarrow">
                <IoChevronBackCircleOutline />
              </span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="next"
            >
              <span className=" visually-hidden" aria-hidden="true"></span>
              <span className="carasoualarrow">
                {" "}
                <IoChevronForwardCircleOutline />
              </span>
            </button>
          </div>
        </div>
      }
      {/* counter section */}
      <div>
        <Counter />
      </div>
      {/* OUR WORK */}
      <div className="lastbannerhome">
        <img src={OurWorkImg} alt="" className="img-fluid  lastbannerimg" />

        <div className="lasbannertext">
          <h1 className="lastbannertittle mb-5">OUR WORK</h1>
          <p>
            Asha properties had successfully established itself over a period of 20 years. Dealing in land development and constructions, our work diversely includes row houses, bungalows, plotting of layout and also raw agricultural land. We have completed 3 projects till now and we currently have one ongoing Project. We have sold 327 plots till date.
          </p>
        </div>
      </div>
      <Contact />
    </div >
  );
}
