import React, { useEffect, useState } from 'react'
import '../styles/contact.css'
// import LeafContactPageMobile from '../Images/LeafContactPageMobile.png'
import NewMobileContactImage from '../Images/NewMobileLeafImage.png'
// import contactImg from '../Images/ContactImageNew.png'
import contactImgNew from '../Images/ContactImageNew2.png'
import { MdLocationPin } from 'react-icons/md'
import { AiOutlineMail } from 'react-icons/ai'
import { BsTelephone } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { BsFillSuitHeartFill } from 'react-icons/bs'
// import copyRightLogo from '../Images/Sumago_logo_footer.png'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function Contact() {

    const [contactDetails, setContactDetails] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [mobile_no, setMobileNo] = useState("")
    const [socialMedia, setSocialMedia] = useState([])

    useEffect(() => {
        axios.get("/get_contactDetails")
            .then(res => {
                console.log("res in contactDetails", res.data.data)
                setContactDetails(res.data.data)
            })
            .catch(err => console.log(err))

        axios.get("/get_socialmedialinks")
            .then(res => {
                console.log("res in contactDetails", res.data.data)
                setSocialMedia(res.data.data)
            })
            .catch(err => console.log(err))
    }, [])

    function scrollToTop() {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }


    // Perform email validation using a regular expression
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    // const mobileRegex = /^\d{10}$/;

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (!emailRegex.test(e.target.value)) {
            e.target.setCustomValidity('Email is incorrect.');
        } else {
            e.target.setCustomValidity('');
        }
    };


    const handleMobileChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
        const truncatedValue = numericValue.slice(0, 10); // Limit to 10 digits
        setMobileNo(truncatedValue);

        if (numericValue.length > 11) {
            e.target.setCustomValidity('Mobile no should be a maximum of 10 digits');
        } else {
            e.target.setCustomValidity('');
        }
    };

    const handleFormSubmit = (e) => {

        // Perform email validation using a regular expression
        // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        // const mobileRegex = /^\d{10}$/;

        // if (!emailRegex.test(email) && !mobileRegex.test(mobile_no)) {
        //     toast.success('Please enter a valid email address or a 10-digit mobile number.');
        //     return;
        // } else if (!emailRegex.test(email)) {
        //     toast.success('Please enter a valid email address');
        //     return;
        // } else if (!mobileRegex.test(mobile_no)) {
        //     toast.success('Please enter a 10-digit mobile number');
        //     return;
        // }

        e.preventDefault()
        let newData = { name, email, message, mobile_no }
        axios.post('/add_contact_us', newData)
            .then(res => {
                console.log(res.data)
                toast.success("We will connect you soon. Thank You!")
            }).catch(err => {
                console.log(err)
                toast.error("Something went wrong")
            })

        setName("")
        setEmail("")
        setMessage("")
        setMobileNo("")
    }

    return (
        <div className='contact-main'>
            {/* <h1>This is Footer Page</h1> */}
            <div className='contact-content'>
                <div className='contact-row-main row'>
                    <div className='col-lg-3 col-12 contact-image'>
                        <img src={contactImgNew} alt="" className='w-100 d-lg-block d-none' />
                        <img src={NewMobileContactImage} alt="" className='w-100 d-lg-none d-block' />
                    </div>
                    <section className='col-lg-9 col-12 contact-details-main'>
                        <div className='details-main'>
                            <div className='contact-margin'>
                                <h1 className='contact-heading'>CONTACT</h1>
                                <div className='details-row-main row'>
                                    <div className='contact-details col-lg-6 col-12'>
                                        {contactDetails !== "" ?
                                            <>
                                                <div className='row'>
                                                    <div className='col-1'>
                                                        <MdLocationPin fill='#91B171' />
                                                    </div>
                                                    <div className='col-11'>
                                                        <h1 className='location'>{contactDetails.address}</h1>
                                                    </div>
                                                </div>
                                                <div className='row mt-4'>
                                                    <div className='col-1'>
                                                        <AiOutlineMail fill='#91B171' />
                                                    </div>
                                                    <div className='col-11'>
                                                        <h1 className='email'>{contactDetails.email}</h1>
                                                    </div>
                                                </div>
                                                <div className='row mt-4'>
                                                    <div className='col-1'><BsTelephone fill='#91B171' /></div>
                                                    <div className='col-11'>
                                                        <h1 className='phone'>{contactDetails.contact_no}</h1>
                                                    </div>
                                                </div>
                                            </> : <h1>No Data Available</h1>
                                        }
                                        <ul className='links'>
                                            <h1>Links</h1>
                                            <div className='row d-lg-block'>
                                                <li className='col-lg-12 col-md-4 col-sm-4 col-6 home'>
                                                    <Link onClick={scrollToTop} to='/'>Home</Link>
                                                </li>
                                                <li className='col-lg-12 col-md-4 col-sm-4 col-6 about'>
                                                    <Link onClick={scrollToTop} to='/about'>About Us</Link>
                                                </li>
                                                <li className='col-lg-12 col-md-4 col-sm-4 col-6 done-projects'>
                                                    <Link onClick={scrollToTop} to='/completed'>Completed Projects</Link>
                                                </li>
                                                <li className='col-lg-12 col-md-4 col-sm-4 col-6 ongoing-projects'>
                                                    <Link onClick={scrollToTop} to='/ongoing'>Ongoing Projects</Link>
                                                </li>
                                                <li className='col-lg-12 col-md-4 col-sm-4 col-6 ongoing-projects'>
                                                    <Link onClick={scrollToTop} to='/property'>Properties</Link>
                                                </li>
                                            </div>
                                        </ul>
                                    </div>
                                    <div className='contact-form col-lg-6 col-12'>
                                        <form className='form-main ms-lg-5 mt-lg-1' onSubmit={handleFormSubmit}>
                                            <div>
                                                <input type="text" placeholder='Name' required
                                                    onChange={(e) => setName(e.target.value)} value={name} />
                                            </div>
                                            <div>
                                                <input type="text" placeholder='Email id' required
                                                    onChange={handleEmailChange} value={email} />
                                            </div>
                                            <div>
                                                <input type="text" placeholder='Contact No.' required
                                                    onChange={handleMobileChange} value={mobile_no} />
                                            </div>
                                            <div>
                                                <textarea id="" cols="23" rows="7" placeholder='Message' required
                                                    onChange={(e) => setMessage(e.target.value)} value={message} />
                                            </div>
                                            <button type="submit" className='btn'>Send</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className='social-icons'>
                            <span className='mt-1'>Follow us</span>
                            {socialMedia.map((media) => {
                                return (
                                    <a href={`${media.link}`} target="_blank" rel="noopener noreferrer">
                                        <img src={media.image} alt="" className='' />
                                    </a>
                                )
                            })}
                        </div> */}

                        <div className='d-lg-block d-none'>
                            <div className='d-md-flex justify-content-around d-block'>

                                <div className='copyright-footer text-center text-lg-start mt-md-4'>
                                    {/* <img src={copyRightLogo} alt="" className='img-fluid copyright-logo' /> */}
                                    {/* <h1 className='d-inline'><span className='text-danger ms-2'>Sumago Infotech Pvt. Ltd.</span> © 2023 All Rights Reserved</h1> */}
                                    <h1 className='d-inline'> © 2023, made with Passion by Sumago Infotech with <BsFillSuitHeartFill fill='red' /> from Nashik</h1>
                                </div>
                                <div className='social-icons text-center text-lg-end mt-3'>
                                    <span className='mt-1'>Follow us</span>
                                    {socialMedia.map((media, index) => (
                                        <OverlayTrigger
                                            key={index}
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip-${index}`}>{media.title}</Tooltip>}
                                        >
                                            <a
                                                href={`${media.link}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img src={media.image} alt={media.title} className='' />
                                            </a>
                                        </OverlayTrigger>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className='d-lg-none d-block'>
                            <div className='d-md-flex justify-content-around d-block'>

                                <div className='social-icons text-center text-lg-end mt-3'>
                                    <span className='mt-1'>Follow us</span>
                                    {socialMedia.map((media, index) => (
                                        <OverlayTrigger
                                            key={index}
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip-${index}`}>{media.title}</Tooltip>}
                                        >
                                            <a
                                                href={`${media.link}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img src={media.image} alt={media.title} className='' />
                                            </a>
                                        </OverlayTrigger>
                                    ))}
                                </div>

                                <div className='copyright-footer text-center text-lg-start mt-md-4'>
                                    {/* <img src={copyRightLogo} alt="" className='img-fluid copyright-logo' /> */}
                                    {/* <h1 className='d-inline'><span className='text-danger ms-2'>Sumago Infotech Pvt. Ltd.</span> © 2023 All Rights Reserved</h1> */}
                                    <h1 className='d-inline'> © 2023, made with Passion by Sumago Infotech with <BsFillSuitHeartFill fill='red' /> from Nashik</h1>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>
            </div>
        </div>
    )
}
