import React, { useEffect } from "react";
import Header from "./Header";
import Contact from '../Components/Contact';
import { useState } from "react";
import Aboutbanner from "../Images/about us.png";
import whatwedoimg from "../Images/Group 3433.png";
import founderimg from "../Images/founderback.png";
// import founder1 from "../Images/founder1.png";
import founderMain from '../Images/PHOTO FOUNDER.png'
// import founder2 from "../Images/founder2.png";
import lastbannerabout from "../Images/aboutimglastbanner.png";
import "../styles/about.css";
import Counter from "./Counter";
import axios from "axios";
import { toast } from "react-toastify";
export default function About() {
  const [aboutUs, setAboutUs] = useState([]);
  const [name, setName] = useState("");
  const [mobile_no, setMobileNo] = useState("")
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    axios
      .get("/get_aboutus")
      .then((res) => {
        console.log("res.data in homes's About Us", res.data.data);
        setAboutUs(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);


  // Perform email validation using a regular expression
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  // const mobileRegex = /^\d{10}$/;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!emailRegex.test(e.target.value)) {
      e.target.setCustomValidity('Email is incorrect.');
    } else {
      e.target.setCustomValidity('');
    }
  };


  const handleMobileChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    const truncatedValue = numericValue.slice(0, 10); // Limit to 10 digits
    setMobileNo(truncatedValue);

    if (numericValue.length > 11) {
      e.target.setCustomValidity('Mobile no should be a maximum of 10 digits');
    } else {
      e.target.setCustomValidity('');
    }
  };

  const handleFormSubmit = (e) => {
    // Perform email validation using a regular expression
    // Perform email validation using a regular expression
    // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    // const mobileRegex = /^\d{10}$/;

    // if (!emailRegex.test(email) && !mobileRegex.test(mobile_no)) {
    //   toast.success('Please enter a valid email address or a 10-digit mobile number.');
    //   return;
    // } else if (!emailRegex.test(email)) {
    //   toast.success('Please enter a valid email address');
    //   return;
    // } else if (!mobileRegex.test(mobile_no)) {
    //   toast.success('Please enter a 10-digit mobile number');
    //   return;
    // }

    e.preventDefault();
    let newData = { name, email, message, mobile_no };
    axios
      .post("/add_contact_us", newData)
      .then((res) => {
        console.log(res.data);
        toast.success("We will connect you soon. Thank You!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });

    setName("");
    setEmail("");
    setMessage("");
    setMobileNo("")
  };

  const founders = [
    {
      founderImage: founderMain,
    },
    // {
    //   founderImage: founder2,
    // },
  ];

  return (
    <div className="about-main">
      <Header />
      {/* banner section */}

      <div className="ongoing-git-form">
        <img src={Aboutbanner} alt="" className="img-fluid w-100" />
        <div>
          <div className="form-class">
            <form className="form-main" onSubmit={handleFormSubmit}>
              <div>
                <h1 className="git-heading">Get In Touch</h1>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Name"
                  required
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Email id"
                  required
                  onChange={handleEmailChange}
                  value={email}
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Contact No"
                  required
                  onChange={handleMobileChange}
                  value={mobile_no}
                />
              </div>
              <div>
                <textarea
                  id=""
                  cols="23"
                  rows="2"
                  placeholder="Message"
                  required
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                />
              </div>
              <button type="submit" className="btn">
                Send
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* wehat we do section */}
      <div>
        <div className="row aboutwhatwedo pb-5 pt-5">
          <div className="col-lg-6 aboutleft">
            <div>
              <h1 className="abouttitle">About us</h1>
            </div>
            {aboutUs.length > 0 ?
              <>
                <div>
                  <h2 className="aboutsubttile mt-5">{aboutUs[0].title}</h2>
                </div>
                <div className="col-lg-11 mt-3">
                  <p className="abouttext ">{aboutUs[0].long_description}</p>
                </div>
              </> : <h2 className="aboutsubttile mt-5">No Data Available</h2>
            }
          </div>
          <div className="col-lg-5 aboutright">
            <div className="text-center ">
              <img
                src={whatwedoimg}
                className="aboutimg img-fluid shadow-lg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      {/* founder section */}
      <div className="row">
        <div className="col-lg-6">
          <div className="abouttitle ms-5">FOUNDER</div>

          <div className="foundertext">
            <div className="row founderimg d-flex justify-content-start">
              {founders.map((founder) => {
                return (
                  <div className="col-4 founders ms-5">
                    <img
                      src={founder.founderImage}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                );
              })}
            </div>
            <div className="founderleftside">
              <p className="founder-description ms-5 mb-lg-0 mb-5">
                Mr Vikas VitthalRao Sawant is a businessman with a working experience of over 25 years in the field of builder and land developer. With the help of this field he is connecting with nature very strongly. He is a civil engineer and the owner of asha properties. And also he is the director of Shri Ganesh sahkari Bank limited, saau leisure pvt ltd, blossom food pvt ltd.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <img src={founderimg} className="img-fluid" alt="" />
        </div>
      </div>
      {/* counter */}
      <Counter />

      {/* lastbannerabout */}
      <div className="lastBannerImage">
        <img src={lastbannerabout} alt="" className="img-fluid" />
      </div>

      <div className="about-contact-section">
        <Contact />
      </div>

    </div>
  );
}
