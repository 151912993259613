import React, { useEffect, useState } from "react";
import Header from './Header'
import Contact from '../Components/Contact';
// import properties from "../Images/FRAM HOUSE.png";
// import properties1 from "../Images/PHOT.png";
// import properties2 from "../Images/PHOTO3.png";
import logo from "../Images/logo 5.png"
import "../styles/property.css";
import axios from "axios";

export default function Property() {

  const [properties, setProperties] = useState([])

  useEffect(() => {
    axios.get("/get_properties")
      .then(res => {
        console.log("res.data.data in properties", res.data.data)
        setProperties(res.data.data)
      }).catch(err => {
        console.log(err);
      })
  }, [])

  // const proprtiesdata = [
  //   {
  //     id: 1,
  //     imgs: properties,
  //     Totalarea: "11 gunthe",
  //     location: "Nashk,Ambad",
  //     plotarea: "5 acre",
  //   },
  //   {
  //     id: 2,
  //     imgs: properties1,
  //     Totalarea: "11 gunthe",
  //     location: "Nashk,Ambad",
  //     plotarea: "5 acre",
  //   },
  //   {
  //     id: 3,
  //     imgs: properties2,
  //     Totalarea: "11 gunthe",
  //     location: "Nashk,Ambad",
  //     plotarea: "5 acre",
  //   },
  // ];

  return (
    <>
      <Header />
      <div className="property-main">
        <div className="col-3">
          <img src={logo} alt="" className=" img-fluid" />
        </div>
        <div className=" text-center titleproperty fw-bolder mb-3">
          PROPERTIES
        </div>
        {properties.length > 0 ?
          <>
            {properties.map((property, index) => {
              return (
                <div className="container mb-5">
                  <div>
                    <img src={property.image} alt="" className="img-fluid w-100 propertyimg" />

                    <div>
                      <div class="card bottom-left col-lg-4 col-md-5 col-sm-5"
                        key={index}
                        style={{
                          backgroundColor: index % 2 === 0 ? "#0A5720A1" : " #0A4057A1",
                        }}>
                        <ul class=" lists ">
                          <li className="m-3 property-title">{(property.title).toUpperCase()}</li>
                          <li className="m-3 ">TOTAL AREA   :{property.total_area}</li>
                          <li className="m-3 ">LOCATION     :{property.location}</li>
                          <li className="m-3 ">PLOT AREA    :{property.plot_area}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </> :  <h1 className="text-center fw-bolder mt-5">No Data Available</h1>
        }
      </div>
      <Contact />
    </>
  );
}
