import React, { useEffect, useState } from 'react'
import '../styles/completed.css'
import Header from '../Components/Header'
import Contact from '../Components/Contact';
// import KikwiProjectsImage from '../Images/DoneProjectImage1.png'
// import BramhaGreenPhase1Image from '../Images/DoneProjectImage2.png'
import KikwiAminityImage1 from '../Images/KikwiAmintyImage.png'
// import BramhaGreenPhase1AminityImage from '../Images/BramhaPhase1AminityImage.png'
// import Icon1 from '../Images/Icon1.png'
// import Icon2 from '../Images/Icon2.png'

// import Icon3 from '../Images/Icon3.png'
// import Icon4 from '../Images/Icon4.png'
// import KikwiGreenLayout from '../Images/KikwiGreenLayout.png'
// import BramhaGreenPhase1Layout from '../Images/BramhaPhase1Layout.png'
import ProjectCarousel from './ProjectCarousel'
import LayoutCarousel from './LayoutCarousel'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'

export default function CompletedProjects() {

  const [projectDetails, setProjectDetails] = useState([])
  const [aminityIcons, setAminityIcons] = useState([])

  const { id } = useParams()

  useEffect(() => {
    axios.get('/get_done_projects')
      .then(res => {
        console.log(`res.data.data in done projects/${id}`, res.data.data);
        setProjectDetails(res.data.data)
      }).catch(err => {
        console.log(err);
      })

    axios.get(`/get_amenities/${id}`)
      .then(res => {
        console.log(`res.data.data in done projects aminities/${id}`, res.data.data);
        setAminityIcons(res.data.data)
      }).catch(err => {
        console.log(err);
      })
    // eslint-disable-next-line
  }, [])

  console.log("projectDetails", projectDetails);

  // const [projectAminitiesImage, setProjectAminitiesImage] = useState(KikwiGreenLayout)
  // const [projectAminitiesId, setProjectAminitiesId] = useState("")
  const [projectAminitiesImage, setProjectAminitiesImage] = useState(KikwiAminityImage1)

  console.log("id through params", id);

  // const projectHighlights = [
  //   {
  //     id: 1,
  //     projectImages: [KikwiProjectsImage, BramhaGreenPhase1Image],
  //     icondetails: [
  //       {
  //         iconId: 1,
  //         iconName: Icon1,
  //         iconImage: KikwiAminityImage1
  //       },
  //       {
  //         iconId: 2,
  //         iconName: Icon2,
  //         iconImage: BramhaGreenPhase1AminityImage
  //       },
  //       {
  //         iconId: 3,
  //         iconName: Icon3,
  //         iconImage: KikwiAminityImage1
  //       },
  //       {
  //         iconId: 4,
  //         iconName: Icon4,
  //         iconImage: BramhaGreenPhase1AminityImage
  //       },
  //     ],
  //     projectLayoutImages: [KikwiGreenLayout, BramhaGreenPhase1Layout],
  //     totalArea: "100 sq.km",
  //     location: "Satpur MIDC",
  //     plotArea: "70 sq.km",
  //     availablePlots: 10,
  //     projectTitle: "Bramha Green Phase 1",
  //     projectDescription: "Be it summer , rains  or winters , Kikwi Green will surpirse you with its picturesque bequty every time you vist . In our day to day stressful lives , we need the tranquil envirnoment which will soothe us , refresh us to a pleasant state of mind . We sure the serene kikwi will leave you spellbound, we have taken care of the base infrastructure and necessities like the roads , security , electricity . Each plot which is a mi",
  //     aminityImage: KikwiAminityImage1,
  //     aminities: ["Entrance Gate", "Architectural design facility", "Exclusive plots with exclusive fencing"]
  //   },
  //   {
  //     id: 2,
  //     projectImages: [KikwiProjectsImage, BramhaGreenPhase1Image],
  //     icondetails: [
  //       {
  //         iconId: 1,
  //         iconName: Icon1,
  //         iconImage: KikwiAminityImage1
  //       },
  //       {
  //         iconId: 2,
  //         iconName: Icon2,
  //         iconImage: BramhaGreenPhase1AminityImage
  //       },
  //       {
  //         iconId: 3,
  //         iconName: Icon3,
  //         iconImage: KikwiAminityImage1
  //       },
  //       {
  //         iconId: 4,
  //         iconName: Icon4,
  //         iconImage: BramhaGreenPhase1AminityImage
  //       },
  //     ],
  //     projectLayoutImages: [KikwiGreenLayout, BramhaGreenPhase1Layout],
  //     totalArea: "250 sq.km",
  //     location: "Gangapur Road",
  //     plotArea: "180 sq.km",
  //     availablePlots: 25,
  //     projectTitle: "Bramha Green Phase 2",
  //     projectDescription: "Be it summer , rains  or winters , Kikwi Green will surpirse you with its picturesque bequty every time you vist . In our day to day stressful lives , we need the tranquil envirnoment which will soothe us , refresh us to a pleasant state of mind . We sure the serene kikwi will leave you spellbound, we have taken care of the base infrastructure and necessities like the roads , security , electricity . Each plot which is a mi",
  //     aminityImage: KikwiAminityImage1,
  //     aminities: ["Swimming pool", "24/7 Hot Shower", "Garden"]
  //   },
  //   {
  //     id: 3,
  //     projectImages: [KikwiProjectsImage, BramhaGreenPhase1Image],
  //     icondetails: [
  //       {
  //         iconId: 1,
  //         iconName: Icon1,
  //         iconImage: KikwiAminityImage1
  //       },
  //       {
  //         iconId: 2,
  //         iconName: Icon2,
  //         iconImage: BramhaGreenPhase1AminityImage
  //       },
  //       {
  //         iconId: 3,
  //         iconName: Icon3,
  //         iconImage: KikwiAminityImage1
  //       },
  //       {
  //         iconId: 4,
  //         iconName: Icon4,
  //         iconImage: BramhaGreenPhase1AminityImage
  //       },
  //     ],
  //     projectLayoutImages: [KikwiGreenLayout, BramhaGreenPhase1Layout],
  //     totalArea: "56 sq.km",
  //     location: "Ambad",
  //     plotArea: "50 sq.km",
  //     availablePlots: 5,
  //     projectTitle: "Kikwi Green",
  //     projectDescription: "Be it summer , rains  or winters , Kikwi Green will surpirse you with its picturesque bequty every time you vist . I n our day to day stressful lives , we need the tranquil envirnoment which will soothe us , refresh us to a pleasant state of mind . We sure the serene kikwi will leave you spellbound, we have taken care of the base infrastructure and necessities like the roads , security , electricity . Each plot which is a mi",
  //     aminityImage: KikwiAminityImage1,
  //     aminities: ["Entrance Gate", "Architectural design facility", "Exclusive plots with exclusive fencing", "Swimming pool", "24/7 Hot Shower", "Garden"]
  //   },
  // ]

  // console.log("projectAminitiesImage", projectAminitiesImage);

  return (
    <>
      <div className='completed-main'>
        <Header />
        <section className='section-1'>
          <div className='project-highlights-main'>
            {projectDetails.filter(projectById => projectById.project_id === Number(id))
              .map((highlight) => {
                return (
                  <>
                    <div className='completed-projects-carousel mt-3 mb-3' key={highlight.project_id}>
                      {/* <h1 className='text-center project-title'>{highlight.name}</h1> */}
                      <ProjectCarousel projectImages={highlight.project_images}
                        projectTotalArea={highlight.area}
                        projectLocation={highlight.address}
                        projectPlotArea={highlight.plot_area}
                        projectAvailablePlots={highlight.available_plot}
                        projectTitle={highlight.name}
                      // projectAminities={highlight.aminities} 
                      />
                    </div>
                    <h1 className='text-center aminity-heading'>{highlight.name}</h1>
                    <div className='project-image-description row'>

                      {/* <div className='col-md-7 col-12 image-main'>
                      <img src={highlight.aminityImage} alt="" className='img-fluid' />
                    </div> */}

                      <div className='col-lg-7 col-12 image-main'>
                        <h1 className='main-amenity-heading text-center'>Amenities</h1>
                        <div className='d-flex justify-content-center'>
                          <div className='outer-circle mt-5'>
                            <div className='inner-circle'>
                              <div className='second-inner-circle'>
                                {/* {
                                projectAminitiesId !== "" ?
                                  highlight.amenities.amenitiy_images
                                    .filter(image => image.aminity_id === projectAminitiesId)
                                    .map(actImage => {
                                      return (
                                        <img src={actImage.images} alt="" className='w-100 h-100 border rounded-circle' />
                                      )
                                    }) : <img src={KikwiAminityImage1} alt="" className='w-100 h-100 border rounded-circle' />
                              } */}
                                <img src={projectAminitiesImage} alt="" className='w-100 h-100 border rounded-circle' />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='icon-class d-flex justify-content-evenly'>
                          {aminityIcons.map((logo) => {
                            return (
                              <div key={logo.id} className='icon-logo ms-3'>
                                <Link
                                  className='d-flex justify-content-center'
                                  onClick={() => {
                                    // setProjectAminitiesId(logo.id)
                                    setProjectAminitiesImage(logo.image)
                                  }}>
                                  <img src={logo.amenityicon} alt="" className='img-fluid align-self-center' />
                                </Link>
                              </div>
                            )
                          })}
                        </div>
                      </div>

                      <div className={`${(highlight.description).length < 1960 ? "description-main" : "description-main-long"} col-lg-5 col-12`}>
                        <h1 className='text-lg-start text-center mt-5'>{highlight.name}</h1>
                        <p className='mt-md-5 mt-5'>{highlight.description}</p>

                        <h1 className="text-lg-start text-center mt-5">FEATURES</h1>
                        <div className='mt-5'>
                          {highlight.features.map((projectFeature, index) => {
                            return (
                              <p className=''>{">"} {projectFeature.feature}</p>
                            )
                          })}
                        </div>
                      </div>
                    </div>

                    {/* <div className="embed-responsive embed-responsive-16by9 mt-5 video-main">
                      <iframe
                        className="embed-responsive-item"
                        // src="https://www.youtube.com/embed/a3ICNMQW7Ok?autoplay=1"
                        src={`${highlight.video_link !== "" ? highlight.video_link : "https://www.youtube.com/embed/a3ICNMQW7Ok?autoplay=1"}`}
                        allowFullScreen
                        title="YouTube Video"
                        width="100%"
                        height="100%"
                      ></iframe>
                    </div> */}

                    <div className='layout-carousel-main mt-5'>
                      <h1 className='layout-heading text-center'>LAYOUT</h1>
                      <LayoutCarousel projectLayouts={highlight.main_layout_image} />
                    </div>
                  </>
                )
              })}
          </div>
        </section>
        <Contact />
      </div>
      {/* </section> */}
      {/* </div> */}
    </>
  )
}
