import React from 'react'
import '../styles/ongoingprojectcarousel.css'
import Slider from 'react-slick';
// import { ImLeaf } from 'react-icons/im'

export default function OngoingProjectCarousel({ ongoingProjectImages, ongoingProjectTotalArea, ongoingProjectLocation, ongoingProjectPlotArea, ongoingProjectAvailablePlots, ongoingProjectTitle }) {

    // Slick carousel settings
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1, // Number of photos to show in each slide
        slidesToScroll: 1, // Number of photos to scroll when navigating
        autoplay: true,
        speed: 1000, // Transition speed in milliseconds
        autoplaySpeed: 3000, // Interval between each slide change
    };

    return (
        <div>
            <div className='project-carousel-main'>
                <Slider {...settings}>

                    {ongoingProjectImages.map(a => {
                        return (
                            <div className='carousel-details-class'>
                                <div className='d-flex justify-content-center carousel-image'>
                                    <img src={a.image} alt="" className='' />
                                </div>
                                <div className='d-flex justify-content-center mb-5'>
                                    <div className='ongoingProject-info'>
                                        <div className='row'>
                                            <p className='col-4 main-p'>TITLE:</p>
                                            <p className='col-8 main-p'>{ongoingProjectTitle}</p>
                                        </div>
                                        <div className='row'>
                                            <p className='col-4 main-p'>TOTAL AREA:</p>
                                            <p className='col-8'>{ongoingProjectTotalArea}</p>
                                        </div>
                                        <div className='row'>
                                            <p className='col-4 main-p'>LOCATION:</p>
                                            <p className='col-8'>{ongoingProjectLocation}</p>
                                        </div>
                                        <div className='row'>
                                            <p className='col-4 main-p'>PLOT AREA:</p>
                                            <p className='col-8'>{ongoingProjectPlotArea}</p>
                                        </div>
                                        <div className='row'>
                                            {/* <p className='col-3'>AVAILABLE PLOTS:</p> */}
                                            <p className='col-4 main-p'>PLOTS:</p>
                                            <p className='col-8 available-plots'>{ongoingProjectAvailablePlots}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className='project-aminities'>
                                    <h1>AMINITIES</h1>
                                    {ongoingProjectAminities.map((aminity, index) => {
                                        return (
                                            <p><ImLeaf /> {aminity} </p>
                                        )
                                    })}
                                </div> */}

                            </div>
                        )
                    })}
                </Slider>
            </div>
        </div>
    )
}
