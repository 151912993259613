import React, { useEffect, useState } from "react";
import "../styles/counter.css";
import CountUp from "react-countup";
import axios from "axios";

const Counter = () => {

  const [count, setCount] = useState("")

  useEffect(() => {
    axios.get("/get_counts")
      .then(res => {
        console.log("res.data in get counts", res.data.counts);
        setCount(res.data.counts)
      }).catch(err => {
        console.log(err)
      })
  }, [])

  const counterMainFeatures = [
    {
      feature: "COMPLETED PROJECTS",
      featureCount: count.length > 0 ? count[0].total_done_projects : 0
      // featureCount: 200,
    },
    {
      feature: "TOTAL PLOTS SOLD",
      featureCount: count.length > 0 ? count[0].total_sold_plots : 0
      // featureCount: 500,
    },
    {
      feature: "ONGOING PROJECTS",
      featureCount: count.length > 0 ? count[0].total_ongoing_projects : 0
      // featureCount: 400,
    },
  ];

  return (
    <div className="bg-success counter-main">
      {counterMainFeatures.length > 0 &&
        <div className="d-flex counter justify-content-lg-evenly justify-content-between align-items-center">
          {counterMainFeatures.map((mainFeature) => {
            return (
              <div className="data">
                <p>{mainFeature.feature}</p>
                <h1 className="text-center">
                  <CountUp
                    // duration={20} 
                    end={mainFeature.featureCount}
                  // start={0}
                  />{mainFeature.featureCount >= 50 ? "+" : ""}
                </h1>
              </div>
            );
          })}
        </div>
      }
    </div>
  );
};

export default Counter;
