import React from 'react'
import '../styles/projectcarousel.css'
import Slider from 'react-slick';
// import { ImLeaf } from 'react-icons/im'

export default function ProjectCarousel({ projectImages, projectTotalArea, projectLocation, projectPlotArea, projectAvailablePlots, projectTitle }) {

  // Slick carousel settings
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1, // Number of photos to show in each slide
    slidesToScroll: 1, // Number of photos to scroll when navigating
    autoplay: true,
    speed: 1000, // Transition speed in milliseconds
    autoplaySpeed: 3000, // Interval between each slide change
  };

  return (
    <div>
      <div className='project-carousel-main'>
        <Slider {...settings}>

          {projectImages.map(a => {
            return (
              <div className='carousel-details-class'>
                <div className='d-flex justify-content-center carousel-image'>
                  <img src={a.image} alt="" className='' />
                </div>
                <div className='d-flex justify-content-center mb-5'>
                  <div className='project-info'>
                    <div className='row'>
                      <p className='col-4 main-p'>TITLE:</p>
                      <p className='col-8 main-p'>{projectTitle}</p>
                    </div>
                    <div className='row'>
                      <p className='col-4 main-p'>TOTAL AREA:</p>
                      <p className='col-8'>{projectTotalArea}</p>
                    </div>
                    <div className='row'>
                      <p className='col-4 main-p'>LOCATION:</p>
                      <p className='col-8'>{projectLocation}</p>
                    </div>
                    <div className='row'>
                      <p className='col-4 main-p'>PLOT AREA:</p>
                      <p className='col-8'>{projectPlotArea}</p>
                    </div>
                    <div className='row'>
                      {/* <p className='col-3'>AVAILABLE PLOTS:</p> */}
                      <p className='col-4 main-p'>PLOTS:</p>
                      <p className='col-8 available-plots'>{projectAvailablePlots}</p>
                    </div>
                  </div>
                </div>

                {/* <div className='project-aminities'>
                  <h1>AMINITIES</h1>
                  {projectAminities.map((aminity, index) => {
                    return(
                      <p><ImLeaf/> {aminity} </p>
                    )
                  })}
                </div> */}
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}
