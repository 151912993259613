import React from 'react'
import '../styles/homesummary.css'
import { Link } from 'react-router-dom';

export default function HomeSummary({ HomeSummaryTitle, HomeSummaryDetails }) {

    const handleLinkClick = () => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };

    return (
        <div className='home-summary-main'>
            <div className='home-summary'>
                <h1 className='summary-title'>{HomeSummaryTitle}</h1>
                <div
                    className={"row"}>
                    {HomeSummaryDetails.map(details => {
                        return (
                            <Link className='text-decoration-none text-dark col-md-4 col-sm-6 col-12'
                                to={`${details.redirectTo}`}
                                key={details.id}
                                onClick={handleLinkClick}>
                                <div className='d-flex justify-content-evenly'>
                                    <div className='inner-circle'>
                                        <h1>{details.summaryFeature}</h1>
                                    </div>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
