import React from 'react'
import '../styles/ongoingprojectsummary.css'
import { Link } from 'react-router-dom'

export default function OngoingProjectSummary({ projectSummaryTitle, projectSummaryDetails }) {

    const handleLinkClick = () => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };

    return (
        <div className='ongoing-projects-summary-main'>
            <div className='projects-summary'>
                <h1 className='summary-title'>{projectSummaryTitle}</h1>
                {projectSummaryDetails.length > 0 ?
                    <div
                        className={`${projectSummaryDetails.length < 2 ? 'd-flex justify-content-center' : "row"}`}>
                        {projectSummaryDetails.map(details => {
                            return (
                                // <Link className='text-decoration-none text-dark col-md-4 col-sm-6 col-12'
                                //     to={`/completed/${details.id}`}
                                //     key={details.id}
                                //     onClick={handleLinkClick}>
                                //     <div className='inner-circle d-flex justify-content-evenly'>
                                //         <h1>{details.summaryFeature}</h1>
                                //     </div>
                                // </Link>

                                <Link
                                    className={`text-decoration-none text-dark 
                                        ${projectSummaryDetails.length < 3 ? "col-md-6" : "col-md-4"}  col-sm-6 col-12`}
                                    to={`/ongoing/${details.project_id}`}
                                    key={details.project_id}
                                    onClick={handleLinkClick}>
                                    <div className='d-flex justify-content-lg-evenly justify-content-around'>
                                        <div className='inner-circle d-flex align-items-center justify-content-center'>
                                            <h1>{details.name}</h1>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}
                    </div> : <h1 className='mt-5 text-center'>No Data Available</h1>
                }
            </div>
        </div>
    )
}
