import React from 'react'
import Navbar from './Navbar'
import '../styles/header.css'

export default function Header() {
    return (
        <div className='navbar-flex' id='navbar-flex-id'>
            <Navbar />
        </div>
    )
}
