import React from 'react'
import '../styles/ongoinglayoutcarousel.css'
import { GlassMagnifier } from 'react-image-magnifiers'


export default function OngoingProjectsLayoutCarousel({ ongoingProjectLayouts }) {


    return (
        <div>
            <div className='ongoingProject-layoutCarousel-main'>
                <div>
                    <GlassMagnifier
                        imageSrc={ongoingProjectLayouts}
                        imageAlt="Layout Image"
                        square
                        magnifierSize="20%"
                    />
                </div>
            </div>
        </div>
    )
}
