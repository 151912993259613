import './App.css';
import MainContact from './Components/MainContact';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Routes, Route } from 'react-router-dom';
import Home from './Components/Home'
import About from './Components/About'
import Projects from './Components/Projects'
import CompletedProjects from './Components/CompletedProjects';
import OngoingProjects from './Components/OngoingProjects'
import OngoingMain from './Components/OngoingMain';
import Property from './Components/Property'
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/*
Add this line whenever you want to use react-slick slider
import Slider from 'react-slick';
*/


function App() {

  // axios.defaults.baseURL = "http://127.0.0.1:8000/api"

  // axios.defaults.baseURL = "https://ashaprop.sumagotest.in/public/api"

  axios.defaults.baseURL = "https://ashaproperties.co/admin/api"

  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/completed' element={<Projects />} />
        <Route path='/completed/:id' element={<CompletedProjects />} />
        <Route path='/ongoing' element={<OngoingMain />} />
        <Route path='/ongoing/:id' element={<OngoingProjects />} />
        <Route path='/property' element={<Property />} />
        <Route path='/contact' element={<MainContact />} />
      </Routes>
    </div>
  );
}

export default App;
