import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
// import LocationLogo from '../Images/LocationLogo.png'
// import {CiLocationOn} from 'react-icons/ci'
import { FaMobileAlt } from 'react-icons/fa'
import { BsTelephone } from 'react-icons/bs'

export default function Navbar() {

    const [activeLink, setActiveLink] = useState('Home'); // Initialize with the default active link
    const location = useLocation();
    // Function to handle link click and update activeLink state

    useEffect(() => {
        const path = location.pathname;
        // Logic to determine the active link based on the current path
        if (path === '/') {
            setActiveLink('Home');
        } else if (path === '/about') {
            setActiveLink('About Us');
        } else if (path === '/completed') {
            setActiveLink('Completed')
        } else if (path === '/ongoing') {
            setActiveLink("Ongoing")
        } else if (path === '/property') {
            setActiveLink("Property")
        } else if (path === '/contact') {
            setActiveLink("Contact")
        } else {
            setActiveLink("")
        }
    }, [location]);

    const [mapLink, setMapLink] = useState([])

    useEffect(() => {
        axios.get('/get_projects')
            .then(res => {
                console.log("res in get projects", res.data.data);
                setMapLink(res.data.data)
            }).catch(err => {
                console.log(err);
            })
    }, [])

    return (
        <nav class="navbar navbar-expand-lg fixed-top bg-body-tertiary">
            <div class="container-fluid">
                <button class="navbar-toggler hamburger-icon" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-2 ms-0">
                        <li className="nav-item ms-lg-5 d-none d-lg-block  ms-md-0">
                            <span className="nav-link  ms-md-0 fw-bold" aria-current="page" href="tel:+918888352777"><FaMobileAlt />+ 91-9021981609</span>
                        </li>
                        <li className="nav-item ms-lg-3 d-none d-lg-block  ms-md-0">
                            <span className="nav-link  ms-md-0 fw-bold" aria-current="page" href="tel:0253-7966831"><BsTelephone className='me-1' />0253-7966831</span>
                        </li>
                        <li className="nav-item ms-lg-5  ms-md-0">
                            <Link className={"nav-link  ms-0"} aria-current="page" to="/"
                                style={{ color: activeLink === 'Home' ? 'green' : '', fontWeight: activeLink === 'Home' ? 'bold' : '' }}>Home</Link>
                        </li>
                        <li className="nav-item ms-lg-5 ">
                            <Link className={`nav-link  ms-0`} to="/about"
                                style={{ color: activeLink === 'About Us' ? 'green' : '', fontWeight: activeLink === 'About Us' ? 'bold' : '' }}>What We Do</Link>
                        </li>
                        <li className="nav-item ms-lg-5 ">
                            <Link className={`nav-link  ms-0`} to='/completed'
                                style={{ color: activeLink === 'Completed' ? 'green' : '', fontWeight: activeLink === 'Completed' ? 'bold' : '' }}>Completed Projects</Link>
                        </li>
                        <li className="nav-item ms-lg-5 ">
                            <Link className={`nav-link  ms-0`} to='/ongoing'
                                style={{ color: activeLink === 'Ongoing' ? 'green' : '', fontWeight: activeLink === 'Ongoing' ? 'bold' : '' }}>Ongoing Projects</Link>
                        </li>
                        <li className="nav-item ms-lg-5 ">
                            <Link className={`nav-link  ms-0`} to='/property'
                                style={{ color: activeLink === 'Property' ? 'green' : '', fontWeight: activeLink === 'Property' ? 'bold' : '' }}>Properties</Link>
                        </li>


                        <li class="nav-item ms-lg-5 dropdown ">
                            <Link class={`nav-link ${mapLink.length > 0 ? "dropdown-toggle" : ""} ms-0`} to="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Location
                            </Link>
                            {mapLink.length > 0 &&
                                <ul class="dropdown-menu location-menu mt-lg-4 mt-0">
                                    {mapLink.map((mlink) => {
                                        return (
                                            <li>
                                                <a class="dropdown-item location-item"
                                                    href={`${mlink.map_link}`}
                                                    target="_blank" rel="noopener noreferrer">{mlink.name}</a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            }
                        </li>

                        <li className="nav-item ms-lg-5 ">
                            <Link className={`nav-link  ms-0`} to='/contact'
                                style={{ color: activeLink === 'Contact' ? 'green' : '', fontWeight: activeLink === 'Contact' ? 'bold' : '' }}>Contact Us</Link>
                        </li>

                        {/* <li className="nav-item ms-lg-5 ">
                            <Link className={`nav-link  ms-0 ${activeLink === 'Contact Us' ? 'text-danger' : ''}`} to='/contact'>Contact Us</Link>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    )
}
