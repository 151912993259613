import React from 'react'
import '../styles/layoutcarousel.css'
import { GlassMagnifier } from 'react-image-magnifiers'


export default function LayoutCarousel({ projectLayouts }) {

    return (
        <div>
            <div className='project-layoutCarousel-main'>
                <GlassMagnifier
                    imageSrc={projectLayouts}
                    imageAlt="Layout Image"
                    square
                    magnifierSize="20%"
                />
            </div>
        </div>
    )
}
