import React, { useEffect, useState } from "react";
import Header from './Header'
import Contact from '../Components/Contact';
import '../styles/ongoing.css'
import OngoingProjectSummary from './OngoingProjectSummary'
import ongoingbanner from '../Images/lemon.png'
import axios from 'axios'
import { toast } from "react-toastify";


function OngoingMain() {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [mobile_no, setMobileNo] = useState("")
    const [projectSummaryDetails, setProjectSummaryDetails] = useState([])

    const projectSummaryTitle = "ONGOING PROJECTS"

    useEffect(() => {
        axios.get('/get_ongoing_projects')
            .then(res => {
                console.log("res.data.data in ongoing projects", res.data.data);
                setProjectSummaryDetails(res.data.data)
            }).catch(err => {
                console.log(err);
            })
    }, [])

    // Perform email validation using a regular expression
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    // const mobileRegex = /^\d{10}$/;

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (!emailRegex.test(e.target.value)) {
            e.target.setCustomValidity('Email is incorrect.');
        } else {
            e.target.setCustomValidity('');
        }
    };


    const handleMobileChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
        const truncatedValue = numericValue.slice(0, 10); // Limit to 10 digits
        setMobileNo(truncatedValue);

        if (numericValue.length > 11) {
            e.target.setCustomValidity('Mobile no should be a maximum of 10 digits');
        } else {
            e.target.setCustomValidity('');
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault()
        let newData = { name, email, message, mobile_no }
        axios.post('/add_contact_us', newData)
            .then(res => {
                console.log(res.data)
                toast.success("We will connect you soon. Thank You!")
            }).catch(err => {
                console.log(err)
                toast.error("Something went wrong")
            })

        // Perform email validation using a regular expression
        // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        // const mobileRegex = /^\d{10}$/;

        // if (!emailRegex.test(email) && !mobileRegex.test(mobile_no)) {
        //     toast.success('Please enter a valid email address or a 10-digit mobile number.');
        //     return;
        // } else if (!emailRegex.test(email)) {
        //     toast.success('Please enter a valid email address');
        //     return;
        // } else if (!mobileRegex.test(mobile_no)) {
        //     toast.success('Please enter a 10-digit mobile number');
        //     return;
        // }

        setName("")
        setEmail("")
        setMessage("")
        setMobileNo("")
    }

    return (
        <div className='ongoing-main'>
            <Header />
            <section className='section-1'>
                <div className='ongoing-git-form'>
                    <img src={ongoingbanner} alt="" className='img-fluid w-100' />
                    <div>
                        <div className='form-class'>
                            <form className='form-main' onSubmit={handleFormSubmit}>
                                <div>
                                    <h1 className="git-heading">Get In Touch</h1>
                                </div>
                                <div>
                                    <input type="text" placeholder='Name'
                                        required
                                        onChange={(e) => setName(e.target.value)}
                                        value={name} />
                                </div>
                                <div>
                                    <input type="text" placeholder='Email id'
                                        required
                                        onChange={handleEmailChange}
                                        value={email} />
                                </div>
                                <div>
                                    <input type="text" placeholder='Contact No'
                                        required
                                        onChange={handleMobileChange}
                                        value={mobile_no} />
                                </div>
                                <div>
                                    <textarea id="" cols="23" rows="2" placeholder='Message'
                                        required
                                        onChange={(e) => setMessage(e.target.value)}
                                        value={message} />
                                </div>
                                <button type="submit" className='btn'>Send</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='completed-projects-summary'>
                    <OngoingProjectSummary projectSummaryTitle={projectSummaryTitle} projectSummaryDetails={projectSummaryDetails} />
                </div>
            </section>
            <Contact />
        </div>
    )
}

export default OngoingMain